import React, { Component } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  Button,
} from "mdbreact";
import { Card, CardHeader } from "reactstrap";
import BreadCumb from "../Department/BreadCumb";
import { PostData } from "../../api/service";

// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]

export default class PrincipalContainer extends Component {
  state = {
    facultyData: [],
    isLoaded: false,
    dept_code: "OFF",
  };

  componentDidMount() {
    let d = {
      dept_code: "OFF",
      type: "PRINCIPAL",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      //console.log(resp)
      this.setState({
        ...resp[0],
        facultyData: resp,
        isLoaded: true,
      });
    });
  }
  render() {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <br />

          {this.state.isLoaded &&
            this.state.facultyData.map((el, index) => {
              return (
                <div>
                  {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}

                  <div>
                    <MDBRow className="mt-2">
                      <MDBCol lg="12" sm="12" xs="12" md="12">
                        <div className="new-principal-div">
                          <h5
                            style={{
                              textAlign: "center",
                              color: "#1E3760",
                              fontSize: "1.5em",
                              marginTop: "10px",
                              fontWeight: 700
                            }}
                          >
                            Principal's Message
                          </h5>
                          <p
                            style={{
                              textAlign: "justify",
                              marginTop: "3vh",
                              fontWeight: 500,
                            }}
                          >
                            <i>
                              Warm greetings to all the current and future
                              stakeholders of Pandu College. As the Principal of
                              this esteemed institution, it gives me great
                              pleasure to represent the Pandu College community.
                              Furthermore, I am proud to say that I have a
                              highly capable, hardworking, and competent team by
                              my side who are dedicated to the development of
                              the institution. I firmly believe that education
                              is the backbone of society, and a well-educated
                              younger generation has the potential to propel our
                              society to the unprecedented height of success.
                              With this belief in mind, I aim to work
                              collaboratively with all stakeholders to establish
                              Pandu College as a distinguished institute within
                              a defined time frame.
                            </i>
                          </p>

                          <br />

                          <div style={{ textAlign: "left" }}>
                          <img
                            alt=""
                            src={"/images/principal_pandu_college.png"}
                            style={{
                              width: "200px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div>
                        <br />
                        <h5 style={{ fontSize: "16px", fontWeight: 700 }}>
                          {el.name}
                        </h5>
                        <h6 style={{ fontSize: "12px" }}>{el.qualification}</h6>
                        <h6 style={{ fontSize: "12px" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px" }}>
                          {el.specifications}
                        </h6>

                        {this.state.isLoaded &&
                          this.state.id != undefined &&
                          el.liveNow != 0 && (
                            <a
                              style={{
                                color: "#1892FF",
                                cursor: "pointer",
                                fontWeight: 700,
                              }}
                              href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                            >
                              Visit Profile
                            </a>
                          )}
                        </div>
                      </MDBCol>

                     
                    </MDBRow>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
